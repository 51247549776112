<template>
  <div>
    <div>
      <big-title>
        Beneficiarios<span>.</span>
      </big-title>
    </div>

    <investor-beneficiaries />
  </div>
</template>

<script>
import InvestorBeneficiaries from "@views/investor/profile/InvestorBeneficiaries";
export default {
  name: "BeneficiariesPage",
  components: {
    InvestorBeneficiaries
  }
}
</script>
